/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*=========================================================================*/
/* [Global Template Styles] */
body {
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  line-height: 1.5em;
  color: #26273F;
}

h1, .h1,
h2, .h2,
h4, .h4,
h5, .h5 {
  font-family: "Libre Baskerville", serif;
}

h1, .h1 {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 3.375rem;
  color: #9C1B39;
}

h2, .h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: #00A69C;
}

h3, .h3 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.75rem;
  color: #9C1B39;
}

h4, .h4 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.125rem;
  color: #00A69C;
}

h5, .h5 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  color: #9C1B39;
}

h6, .h6 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
  color: #9C1B39;
}

a {
  color: #9C1B39;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

.swiper-container {
  width: 100%;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.subpage-banner {
  min-height: 1rem;
  background: #00A69C;
}

.subpage-banner img {
  width: 100%;
}

.text-white {
  color: #FFFFFF;
}

.text-maroon {
  color: #9C1B39 !important;
}

.text-green {
  color: #00A69C !important;
}

.bg-green, .bg-maroon, .bg-grey {
  background: #00A69C;
  color: #FFFFFF;
}

.bg-green a, .bg-green h1, .bg-green .h1, .bg-green h2, .bg-green .h2, .bg-green h3, .bg-green .h3, .bg-green h4, .bg-green .h4, .bg-green h5, .bg-green .h5, .bg-green h6, .bg-green .h6, .bg-maroon a, .bg-maroon h1, .bg-maroon .h1, .bg-maroon h2, .bg-maroon .h2, .bg-maroon h3, .bg-maroon .h3, .bg-maroon h4, .bg-maroon .h4, .bg-maroon h5, .bg-maroon .h5, .bg-maroon h6, .bg-maroon .h6, .bg-grey a, .bg-grey h1, .bg-grey .h1, .bg-grey h2, .bg-grey .h2, .bg-grey h3, .bg-grey .h3, .bg-grey h4, .bg-grey .h4, .bg-grey h5, .bg-grey .h5, .bg-grey h6, .bg-grey .h6 {
  color: #FFFFFF;
}

.bg-maroon {
  background: #9C1B39;
}

.bg-grey {
  background: #26273F;
}

.bg-pattern {
  position: relative;
  display: block;
  background-image: url("../images/background-pattern-transparent.svg");
  background-position: center 70%;
  background-repeat: repeat;
  background-size: 1700px;
}

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon-heart::before {
  content: ' ';
  display: inline-block;
  height: 1.25em;
  width: 1.25em;
  background: url("../images/heart-white.svg") no-repeat center center;
  background-size: contain;
  vertical-align: -20%;
}

.btn {
  text-transform: uppercase;
  border-radius: 100rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: .625em 1.75rem;
  border: none;
}

.btn-lg {
  font-size: 1.5rem;
  line-height: 1.875rem;
  padding: 1.5rem 3rem;
}

.btn-link {
  color: inherit;
}

.btn-link:hover, .btn-link:focus {
  color: inherit;
  text-decoration: none;
}

.btn.btn-primary {
  background-color: #00A69C;
}

.btn.btn-primary:hover {
  background-color: #9C1B39;
}

.btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #00A69C;
  box-shadow: 0 0 0 0.2rem rgba(0, 166, 156, 0.5);
}

.btn.btn-secondary {
  background-color: #9C1B39;
}

.btn.btn-secondary:hover {
  background-color: #00A69C;
}

.btn.btn-secondary:focus, .btn.btn-secondary.focus, .btn.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #9C1B39;
  box-shadow: 0 0 0 0.2rem rgba(156, 27, 57, 0.5);
}

/* Large desktop */
/* Desktop */
@media (max-width: 991.98px) {
  .aspect4x3 {
    aspect-ratio: 4 / 3;
  }
  .aspect6x8 {
    aspect-ratio: 6 / 8;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  html {
    font-size: 12px;
  }
  .d-xs-none {
    display: none !important;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  padding: 2.5rem 1rem;
  color: #9C1B39;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.header .h-logo {
  max-width: 290px;
}

.header .navbar-nav {
  flex-wrap: wrap-reverse;
}

.header .navbar-nav > .nav-item .nav-link {
  margin-left: 1.75vw;
  padding: .25rem;
  color: #26273F;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-transform: uppercase;
}

.header .navbar-nav > .nav-item .nav-link:first-child .nav-link {
  margin-left: 0;
}

.header .navbar-nav > .nav-item.active .nav-link,
.header .navbar-nav > .nav-item .nav-link:hover, .header .navbar-nav > .nav-item .nav-link:focus {
  color: #9C1B39;
}

.header .navbar-nav > .nav-item .btn {
  margin-left: 2.5vw;
}

.navbar-toggler {
  display: block;
  width: 100%;
  padding: 1rem 0;
  background-color: #9C1B39;
  color: white;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .header .navbar-nav > .nav-item .nav-link {
    margin-left: .75vw;
    padding: .125rem;
    font-size: 1rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 2rem 0;
  }
  .header .navbar-nav > .nav-item .nav-link {
    margin-left: 0;
    margin-right: 2.5vw;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    text-align: center;
    padding: 2rem 0;
  }
  .header .navbar-nav > .nav-item {
    width: 100%;
  }
  .header .navbar-nav > .nav-item .nav-link {
    width: 100%;
    padding: .25rem;
    margin: .25rem 0;
  }
  .header .navbar-nav > .nav-item .btn {
    margin: .25rem 0;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 4rem;
}

.page h1.line {
  position: relative;
}

.page h1.line::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: calc(-4rem - 15px);
  width: calc(4rem - 15px);
  height: 2px;
  background-color: #9C1B39;
}

.page-homepage .page-contents {
  padding: 0;
  display: flex;
  position: relative;
  z-index: 0;
}

.page-homepage .page-contents::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url("../images/background-pattern.svg") left top repeat;
  background-size: 300px;
  opacity: 0.15;
}

.page-homepage .homepage-content {
  flex-basis: 60%;
  padding: 6rem;
  position: relative;
}

.page-homepage .homepage-prize-image {
  padding: 2rem;
}

.page-homepage .homepage-image {
  flex-basis: 40%;
  padding: 8rem 5rem;
  position: relative;
  z-index: 0;
  background-size: cover;
  background-position: center center;
}

.page-homepage .homepage-image h2 {
  margin-bottom: 0.7em;
  font-size: 42px;
}

.card-deck .card-prize.card-prize-first {
  flex-grow: 1.5;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .page {
    padding: 3rem;
  }
  .page-homepage .page-contents {
    display: block;
  }
  .page-homepage .homepage-content {
    width: 100%;
    display: block;
  }
  .page-homepage .homepage-content-prizes {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .page-homepage .homepage-prize-image {
    padding: 0rem;
  }
  .page-homepage .homepage-image {
    width: 100%;
    display: block;
  }
  .page-homepage .page-contents .featured-image {
    padding: 0;
    height: 400px;
  }
  .page-homepage .homepage-image.featured-into {
    padding: 2rem;
  }
  .bg-pattern::before {
    display: none;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    padding: 1.5rem;
  }
  .page-homepage .homepage-image {
    flex-basis: 100%;
  }
  .page-homepage .page-contents {
    display: block;
  }
  .page-homepage .homepage-content {
    width: 100%;
    padding: 2rem;
    display: block;
  }
  .page-homepage .homepage-content-prizes {
    padding: 1rem 2rem;
  }
  .page-homepage .homepage-content-prizes h3 {
    font-size: 2.4rem;
  }
  .page-homepage .homepage-prize-image {
    padding: 0rem;
  }
  .page-homepage .homepage-image {
    width: 100%;
    display: block;
  }
  .bg-pattern::before {
    display: none;
  }
  .page-homepage .page-contents .featured-image {
    padding: 0;
    height: 300px;
  }
  .page-homepage .homepage-image.featured-into {
    padding: 2rem;
  }
}

/*=========================================================================*/
/* [Ticket Form Template Styles] */
#ticket-form {
  padding: 1rem;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

#ticket-form h4 {
  color: #9C1B39;
  font-size: 2.2rem;
}

#ticket-form .form-control:disabled, #ticket-form .form-control[readonly] {
  background: transparent;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  padding: 3rem;
  font-size: 1rem;
}

.footer .f-logo {
  max-width: 300px;
}

.footer .acnc-logo {
  max-width: 140px;
}

.footer .nav .nav-link {
  text-transform: uppercase;
  padding: 0;
  margin-right: calc(5.5vw / 4);
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    padding: 2rem 1rem;
  }
  .footer .nav .nav-link {
    margin-right: 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 2rem 0;
    text-align: center;
  }
  .footer .nav .nav-link {
    margin-right: 0;
    margin-bottom: .5rem;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
