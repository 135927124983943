/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */
	$font-primary: 'Mulish', sans-serif;;
	$font-secondary: 'Libre Baskerville', serif;

    $color-white: #FFFFFF;
    $color-primary: #9C1B39;
	$color-secondary: #00A69C;
	$color-grey: #26273F;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
        font-family: $font-primary;
		font-size: 20px;
		line-height: 1.5em;
        color: $color-grey;
	}

	h1, .h1,
	h2, .h2,
	h4, .h4,
	h5, .h5
	{
		font-family: $font-secondary;
	}

	h1, .h1 {
		font-size: 2.625rem;
		font-weight: 700;
		line-height: 3.375rem;
		color: $color-primary;
	}

	h2, .h2 {
		font-size: 2rem;
		font-weight: 700;
		line-height: 2.5rem;
		color: $color-secondary;
	}

	h3, .h3 {
		font-size: 1.875rem;
		font-weight: 400;
		line-height: 2.75rem;
		color: $color-primary;
	}

	h4, .h4 {
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2.125rem;
		color: $color-secondary;
	}

	h5, .h5 {
		font-size: 1.25rem;
		font-weight: 400;
		line-height: 1.875rem;
		color: $color-primary;
	}

	h6, .h6 {
		font-size: 1.125rem;
		font-weight: 700;
		line-height: 1.625rem;
		color: $color-primary;
	}


	a {
		color: $color-primary;
	}

	img
	{
		max-width: 100%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	.swiper-container
	{
		width: 100%;
	}

		.swiper-container .swiper-slide img
		{
			width: 100%;
		}

	.subpage-banner
	{
		min-height: 1rem;
		background: $color-secondary;
	}

	.subpage-banner img
	{
		width: 100%;
	}

    .text-white {
        color: $color-white;
    }

    .text-maroon {
        color: $color-primary !important;
    }

    .text-green {
        color: $color-secondary !important;
    }


    .bg-green, .bg-maroon, .bg-grey {
		background: $color-secondary;
		color: $color-white;

        a, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            color: $color-white;
        }
    }

    .bg-maroon {
        background: $color-primary;
    }

    .bg-grey {
        background: $color-grey;
    }

    .bg-pattern {
        position: relative;
		display: block;
		background-image: url("../images/background-pattern-transparent.svg");
		background-position: center 70%;
		background-repeat: repeat;
		background-size: 1700px;
    }

	.bg-image {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.icon-heart {
		&::before {
			content: ' ';
			display: inline-block;
			height: 1.25em;
			width: 1.25em;
			background: url("../images/heart-white.svg") no-repeat center center;
			background-size: contain;
			vertical-align: -20%;
		}
	}

    .btn {
        text-transform: uppercase;
        border-radius: 100rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
		padding: .625em 1.75rem;
		border: none;
    }

	.btn-lg {
		font-size: 1.5rem;
		line-height: 1.875rem;
		padding: 1.5rem 3rem;
	}

    .btn-link {
        color: inherit;

        &:hover, &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

	.btn.btn-primary {
		background-color: $color-secondary;

		&:hover {
			background-color: $color-primary;
		}

		&:focus, &.focus, &:not(:disabled):not(.disabled):active
        {
			background-color: $color-secondary;
          	box-shadow: 0 0 0 0.2rem rgba($color-secondary, 0.5)
        }
    }

	.btn.btn-secondary {
		background-color: $color-primary;

		&:hover {
			background-color: $color-secondary;
		}

        &:focus, &.focus, &:not(:disabled):not(.disabled):active
        {
			background-color: $color-primary;
          	box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5)
        }
    }

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	@media (max-width: 991.98px) {
		.aspect4x3 {
			aspect-ratio: 4 / 3;
		}
		.aspect6x8 {
			aspect-ratio: 6 / 8;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		html
		{
			font-size: 14px;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		html
		{
			font-size: 12px;
		}

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none !important;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

    .header {
        padding: 2.5rem 1rem;
        color: $color-primary;
		position: relative;
		z-index: 2;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);

        .h-logo {
            max-width: 290px;
        }

        .navbar-nav {
			flex-wrap: wrap-reverse;
            > .nav-item {
				.nav-link {
					margin-left: 1.75vw;
					padding: .25rem;
					color: $color-grey;
					font-size: 1.125rem;
					line-height: 1.375rem;
					text-transform: uppercase;

					&:first-child .nav-link
					{
						margin-left: 0;
					}
				}

				&.active .nav-link,
				.nav-link:hover, .nav-link:focus {
					color: $color-primary;
				}

				.btn {
					margin-left: 2.5vw;
				}
            }
        }
    }

	.navbar-toggler
	{
		display: block;
		width: 100%;
		padding: 1rem 0;

		background-color: $color-primary;

		color: rgb(255, 255, 255);
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.header {
			.navbar-nav {
				> .nav-item {
					.nav-link {
						margin-left: .75vw;
						padding: .125rem;
						font-size: 1rem;
					}
				}
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			padding: 2rem 0;

			.navbar-nav {
				> .nav-item {
					.nav-link {
						margin-left: 0;
						margin-right: 2.5vw;
					}
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header
		{
			text-align: center;
			padding: 2rem 0;

			.navbar-nav {
				> .nav-item {
					width: 100%;
					.nav-link {
						width: 100%;
						padding: .25rem;
						margin: .25rem 0;
					}
					.btn {
						margin: .25rem 0;
					}
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 4rem;

        h1.line
        {
            position: relative;

            &::before
            {
                content: ' ';
                position: absolute;
                top: 50%;
                left: calc(-4rem - 15px);
                width: calc(4rem - 15px);
                height: 2px;
                background-color: $color-primary;
            }
        }
	}

    .page-homepage .page-contents
    {
        padding: 0;
        display: flex;
		position: relative;
		z-index: 0;

		&::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			background: url("../images/background-pattern.svg") left top repeat;
			background-size: 300px;
			opacity: 0.15;
		}
    }

    .page-homepage .homepage-content
    {
        flex-basis: 60%;
        padding: 6rem;
        position: relative;
    }

    .page-homepage .homepage-prize-image
    {
        padding: 2rem;
    }

    .page-homepage .homepage-image
    {
        flex-basis: 40%;
        padding: 8rem 5rem;
		position: relative;
		z-index: 0;

		background-size: cover;
		background-position: center center;

		h2
		{
			margin-bottom: 0.7em;
			font-size: 42px;
		}
    }

    .card-deck {
        .card-prize {
            //margin-bottom: 2rem;

            &.card-prize-first {
                flex-grow: 1.5;
            }
        }
    }


	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.page
		{
			padding: 3rem;
		}

		.page-homepage .page-contents
		{
			display: block;
		}

		.page-homepage .homepage-content
		{
			width: 100%;
			display: block;
		}

		.page-homepage .homepage-content-prizes
		{
			padding-bottom: 1rem;
			padding-top: 1rem;
		}

		.page-homepage .homepage-prize-image
		{
			padding: 0rem;
		}

		.page-homepage .homepage-image
		{
			width: 100%;
			display: block;
		}

		.page-homepage .page-contents .featured-image
		{
			padding: 0;
			height: 400px;
		}

		.page-homepage .homepage-image.featured-into
		{
			padding: 2rem;
		}

		.bg-pattern::before
		{
			display: none;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			padding: 1.5rem;
		}

		.page-homepage .homepage-image
		{
			flex-basis: 100%;
		}

		.page-homepage .page-contents
		{
			display: block;
		}

		.page-homepage .homepage-content
		{
			width: 100%;
			padding: 2rem;
			display: block;
		}

		.page-homepage .homepage-content-prizes
		{
			padding: 1rem 2rem;

			h3
			{
				font-size: 2.4rem;
			}
		}

		.page-homepage .homepage-prize-image
		{
			padding: 0rem;
		}

		.page-homepage .homepage-image
		{
			width: 100%;
			display: block;
		}

		.bg-pattern::before
		{
			display: none;
		}

		.page-homepage .page-contents .featured-image
		{
			padding: 0;
			height: 300px;
		}

		.page-homepage .homepage-image.featured-into
		{
			padding: 2rem;
		}
	}

	/*=========================================================================*/

	/* [Ticket Form Template Styles] */

    #ticket-form {
		padding: 1rem;
		margin-bottom: 1rem;
		-webkit-box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
		box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);

		h4
		{
			color: $color-primary;
			font-size: 2.2rem;
		}

		.form-control:disabled, .form-control[readonly] {
            background: transparent;
        }
    }

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer {
        padding: 3rem;
		font-size: 1rem;

        .f-logo {
            max-width: 300px;
        }

		.acnc-logo {
			max-width: 140px;
		}

        .nav {
            .nav-link {
				text-transform: uppercase;
                padding: 0;
                margin-right: calc(5.5vw / 4);
            }
        }
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer {
			padding: 2rem 1rem;

			.nav {
				.nav-link {
					margin-right: 0;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer {
			padding: 2rem 0;
			text-align: center;

			.nav {
				.nav-link {
					margin-right: 0;
					margin-bottom: .5rem;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}
